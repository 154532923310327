import { setAddHotels } from 'actions/flightActions';
import Helper from 'app/modules/Helper';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Switch from 'react-switch';

type AddHotelsSwitchProps = {
	lng: string;
	addHotelsChecked: boolean;
	onlyMobile: boolean;
	setAddHotels: (toggle: boolean) => void;
};

const AddHotelsSwitch: FC<AddHotelsSwitchProps> = (props) => {
	const { t } = useTranslation();
	const { lng, setAddHotels, addHotelsChecked } = props;
	const isRtl = Helper.isRtlLanguage(lng);

	if (props.onlyMobile && Helper.isDesktop()) return <></>;

	return (
		<div
			className={classNames('col-12 flex justify-content-start align-items-center d-flex', {
				'rtl-direction': isRtl,
			})}
			id="add-hotels-switch-container"
		>
			<Switch
				checked={addHotelsChecked}
				onChange={setAddHotels}
				onColor="#cdebf7"
				onHandleColor="#049dd9"
				handleDiameter={20}
				uncheckedIcon={false}
				checkedIcon={false}
				boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
				activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
				height={16}
				width={34}
				className="react-switch me-2"
				id="add-hotels-switch"
			/>
			<span
				id="add-hotels-switch-label"
				onClick={() => setAddHotels(!addHotelsChecked)}
				className={classNames('cs-label', {
					'me-3': isRtl,
				})}
			>
				{t('keywords.addHotels')}
			</span>
		</div>
	);
};

export default connect(null, { setAddHotels })(AddHotelsSwitch);

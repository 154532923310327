import { ItemType, localeMap } from 'app/modules/Constants';
import classnames from 'classnames';
import { addDays, format, isAfter, isDate } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { selectFromDate, selectToDate } from '../../../actions/flightActions';
import DatePicker from '../Common/DatePicker';
import FadeTransition from '../Common/FadeTransition';

type DatePickerFromProps = {
	selectedToDate: Date;
	selectedFromDate: Date;
	name: string;
	openedInput?: string;
	openedChildInput?: string;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	selectedTripType?: ItemType;
	lng: string;
	selectToDate: (date: Date) => void;
	selectFromDate: (date: Date) => void;
	openInput: (name?: string) => void;
};

type DatePickerFromState = {
	in: boolean;
};

class DatePickerFrom extends React.Component<DatePickerFromProps, DatePickerFromState> {
	constructor(props: DatePickerFromProps) {
		super(props);
		this.state = {
			in: false,
		};
	}

	onSelectDate = (from: Date, keyboardNavigation = false) => {
		let to = this.props.selectedToDate;

		if (!isDate(from)) {
			from = new Date();
		} else {
			from = new Date(from);
		}
		if (!isDate(to)) {
			to = addDays(from, 1);
		} else {
			to = new Date(to);
		}
		const fromDate = from.getTime();
		const toDate = to.getTime();
		if (isAfter(fromDate, toDate)) {
			to = new Date(from);
		}
		this.props.selectFromDate(from);
		this.props.selectToDate(to);
		if (keyboardNavigation) {
			return;
		}
		this.openCalendar(true);
	};

	openCalendar = (openToDateCalendar?: boolean) => {
		openToDateCalendar = openToDateCalendar || false;
		this.setState(
			{
				in: !this.state.in,
			},
			() => {
				if (this.state.in) {
					this.props.openInput(this.props.name);
				} else if (!openToDateCalendar || this.props.selectedTripType?.value === 'one-way') {
					this.props.openInput();
				} else if (openToDateCalendar) {
					if (this.props.autoJump) this.props.openInput('date-to');
					else this.props.openInput();
				}
			}
		);
	};

	componentDidUpdate(prevProps: DatePickerFromProps) {
		if (!this.props.openedInput) {
			if (this.state.in) {
				this.setState({
					in: false,
				});
			}
		} else if (this.props.openedInput === this.props.name && this.props.openedInput !== prevProps.openedInput) {
			if (!this.state.in) {
				this.setState({
					in: true,
				});
			}
		}
	}

	render() {
		const { lng } = this.props;
		const { name, selectedFromDate } = this.props;
		const dateDisplayFormat = lng === 'jp' ? 'M月 d日 (EEE)' : 'EEE, MMM d';

		return (
			<div
				id={name}
				className={classnames('flex-1', {
					'open-input': this.props.openedInput === name,
					'input-box': !this.props.preventSetInputBoxClassName,
				})}
			>
				<div
					className={classnames('input-control justify-content-between filled', {
						focused: this.props.openedInput === name,
					})}
					aria-label={format(this.props.selectedFromDate, dateDisplayFormat, { locale: localeMap[lng] })}
				>
					<label htmlFor={`${name}-input`} className="hidden-input-label">
						<input type="text" className="hidden-input" id={`${name}-input`} />
					</label>
					<div className="date-picker d-flex align-items-center flex-1" onClick={() => this.openCalendar()}>
						<i className="icon-date" />
						<div className="selected">
							{format(this.props.selectedFromDate, dateDisplayFormat, { locale: localeMap[lng] })}
						</div>
					</div>
				</div>
				<div style={{ position: 'relative' }}>
					<FadeTransition timeout={500} in={this.props.openedInput === name}>
						<DatePicker before={new Date()} selected={selectedFromDate} onSelectDate={this.onSelectDate} />
					</FadeTransition>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	selectedFromDate: state.flight.selectedFromDate,
	selectedToDate: state.flight.selectedToDate,
	openedInput: state.base.openedInput,
	selectedTripType: state.flight.selectedTripType,
	autoJump: state.base.autoJump,
});

export default connect(mapStateToProps, { selectFromDate, openInput, selectToDate })(DatePickerFrom);
